import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'
import BannerSection from '../components/BannerSection'
import { Button } from '../components/Common/Button'
import NewsSectionBlock from '../components/NewsSectionBlock'
import NewsQuoteBlock from '../components/NewsQuoteBlock'
import Typography from '../components/Common/Typography'
import Link from '../components/Common/Link'
import { toLink } from '../utils'
import cn from 'classnames'

import * as s from '../pages/news.module.scss'

const SixReasonsToUseInspection: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  return (
    <>
      <SEO
        title="6 reasons to digitize construction QA/QC with signax inspection"
        description="Many people associate panoramic photography or 360-degree photos with Google Maps. However, SIGNAX INSPECTION is not just a service for creating panoramic images; it provides the capability to use 360-degree photos on construction sites, linking shooting points to plans, and comparing 360-degree images from different dates."
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title={`6 REASONS TO DIGITIZE CONSTRUCTION QA/QC WITH SIGNAX INSPECTION`}
        subTitle={`Remote and digital monitoring of construction sites using 360-degree photos`}
        titleSize={60}
        imgStyle={{ height: '467px' }}
      />
      <NewsSectionBlock date="August 05, 2024">
        <Typography variant="h1" color="blue">
          6 reasons to digitaze construction QA/QC with
          <br />
          SIGNAX INSPECTION
        </Typography>
        <Typography variant="body1">
          Many people think of panoramic photography or 360-degree photos only
          in the context of Google Maps. However, SIGNAX INSPECTION offers more
          than just creating panoramic images. It provides the capability to use
          360-degree photos on construction sites, linking shooting points to
          plans, and comparing 360-degree images from different dates. This is
          the first step towards digitizing construction inspection, making it
          more flexible and functional. We have prepared six not-so-obvious use
          cases for SIGNAX INSPECTION on your construction projects.
        </Typography>
        <NewsQuoteBlock
          avatar={images.avatar.childImageSharp.gatsbyImageData}
          name="Nikita Pospelov"
          position="Global Business Director at SIGNAX"
          text="“With projects in different countries and time zones, we use SIGNAX INSPECTION to track progress and share visual milestones of the projects with our customers.”"
        />
        <Typography variant="h2" size={24} mb={16}>
          1. Assessment of Work Completion on the Construction
        </Typography>
        <Typography variant="body1">
          Site Engineers can only sometimes leave the office to confirm the fact
          and volume of completed work. With INSPECTION, it's enough to select
          the shooting point at the work site to check/confirm work completion,
          quality, and approximate volume of work done over the week.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/6-reasons-to-use-inspection/image-1.jpg"
            alt="Confirmation of work completion with 360-degree photos"
            title="Confirmation of work completion with 360-degree photos"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Confirmation of work completion with 360-degree photos
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          2. Confirmation of Hidden Work
        </Typography>
        <Typography variant="body1">
          With only one model, it's difficult to confirm or track the fact that
          work was done after finishing work. With inspection, you have BEFORE
          and AFTER photos, such as when closing communications with finishing
          materials. 360-degree images can determine communications behind the
          wall or ceiling cladding.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/6-reasons-to-use-inspection/image-2.jpg"
            alt="Comparison of 360-degree photos before and after installation"
            title="Comparison of 360-degree photos before and after installation"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Comparison of 360-degree photos before and after installation
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          3. Site Housekeeping and Safety Control Tracking
        </Typography>
        <Typography variant="body1">
          The cause of unfinished work, stored trash, or issuing safety
          violation notices is no longer a problem. INSPECTION helps identify
          violations that were missed during the site walkthrough.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/6-reasons-to-use-inspection/image-3.jpg"
            alt="Comparison of 360-degree photos before and after resolving an issue"
            title="Comparison of 360-degree photos before and after resolving an issue"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Comparison of 360-degree photos before and after resolving an issue
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          4. Visual Log of Events on the Construction Site
        </Typography>
        <Typography variant="body1">
          Track changes and completed volumes with 360-degree photos. INSPECTION
          allows, for example, to monitor the volume of work done by a crew
          before leaving the site and the volume of work the new contractor
          needs to perform.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/6-reasons-to-use-inspection/image-4.jpg"
            alt="Recording of unfinished work volumes"
            title="Recording of unfinished work volumes"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Recording of unfinished work volumes
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          5. Resolving Conflicts During Meetings, 360-degree
        </Typography>
        <Typography variant="body1">
          Photos allow you to be on the construction site while in the office
          and visualize what you are discussing with colleagues. With SIGNAX
          INSPECTION, meetings become more virtual and informative, reducing
          time spent on arguments and focusing more on solving critical problems
          and tasks.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/6-reasons-to-use-inspection/image-5.jpg"
            alt="Joint use of INSPECTION and DASHBOARD in meetings"
            title="Joint use of INSPECTION and DASHBOARD in meetings"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Joint use of INSPECTION and DASHBOARD in meetings
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          6. BIM Integration with Comparison Features
        </Typography>
        <Typography variant="body1">
          SIGNAX INSPECTION integrates not only 360-degree photos but also
          panoramic points of view within BIM models. You can set up viewpoints
          in Navisworks, then export them to INSPECTION using the{' '}
          <Link href="/tools/" target="_blank" rel="nofollow noreferrer">
            TOOLS
          </Link>{' '}
          module. This allows you to create a comprehensive album of these
          models that you can easily share with clients for review, without
          needing additional software. Additionally, you can use 360-degree
          photos for design supervision, comparing design solutions with the
          actual state of work.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/solutions/6-reasons-to-use-inspection/image-6.jpg"
            alt="Comparison of design solutions with actual work using 360-degree photos"
            title="Comparison of design solutions with actual work using 360-degree photos"
            placeholder="blurred"
          />
          <Typography variant="body2" color="gray">
            Comparison of design solutions with actual work using 360-degree
            photos
          </Typography>
        </div>
        <Typography variant="h2" size={24} mb={16}>
          Conclusion
        </Typography>
        <Typography variant="body1">
          SIGNAX INSPECTION is a simple and functional tool, making it an
          essential solution for construction sites. We have presented six
          examples where SIGNAX INSPECTION becomes a must-have on any
          construction site. Whether it is assessment, hidden work confirmation,
          safety, and cleanliness control, maintaining a visual log of events,
          or resolving conflicts during meetings, SIGNAX INSPECTION allows you
          to stay informed, access up-to-date data, and make well-informed
          decisions.
        </Typography>
        <Typography variant="body1">
          Explore sample of INSPECTION shared album, by clicking the button
          below. Want to unlock the <b>full potential</b> of INSPECTION? We
          provide a detailed demonstrations and support with implementation.
          Contact us for a <b>FREE 30-day trial</b> and experience all
          INSPECTION's features.
        </Typography>

        <div className="flex flex-wrap justify-center mt-8">
          <Button
            size="normal"
            className={cn('mb-4 ml-1 mr-1')}
            onClick={() => toLink('https://pa.signax.io/public/album/mbqga')}
          >
            Sample
          </Button>
          <Button
            size="normal"
            className={cn('mb-4 ml-1 mr-1')}
            onClick={() => navigate('/contact/')}
          >
            Contact Us
          </Button>
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default SixReasonsToUseInspection

const imagesQuery = graphql`
  query {
    avatar: file(relativePath: { eq: "author/Nikita_Pospelov.png" }) {
      childImageSharp {
        gatsbyImageData(width: 50, placeholder: BLURRED)
      }
    }
    banner: file(
      relativePath: { eq: "solutions/6-reasons-to-use-inspection/banner.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
